import React from "react";
import Icon from "../Common/Icon";
import { useSelector } from "react-redux";

const TopElements = () => {
  const { mirrorWidth, mirrorHeight, orderAmount } = useSelector((state) => state.mirror);

  const {
    baseFee,
    yanTamKaplamaFee,
    facetaFee,
    ledIsigiFee,
    aynaIsitmaFee,
    rafFee,
    makyajIsikFee,
    makyajTypeFee,
    sizdirmazlikFee,
    bluetoothFee,
    makyajFee,
    soketFee,
    saatFee,
    isiSensorFee,
    kareSensorFee,
    gecisAnahtariSensorFee,
    yataySensorFee,
    ciftLedFee,
    suspansiyonFee,
  } = useSelector((state) => state.mirrorFee);

  const dimensionFee = mirrorWidth > 1200 && mirrorHeight > 800 ? 49.99 : 0;

  const total = Number(
    baseFee +
      (yanTamKaplamaFee +
        facetaFee +
        rafFee +
        makyajIsikFee +
        makyajTypeFee +
        ledIsigiFee +
        sizdirmazlikFee +
        bluetoothFee +
        makyajFee +
        soketFee +
        saatFee +
        isiSensorFee +
        kareSensorFee +
        yataySensorFee +
        ciftLedFee +
        suspansiyonFee +
        aynaIsitmaFee +
        gecisAnahtariSensorFee +
        dimensionFee) *
        orderAmount
  ).toFixed(2);

  return (
    <div className="flex justify-between w-full">
      <div className="relative">
        <Icon className="!p-[10px]" src="/newMenuAssets/basket.png" />
        <div className="absolute flex flex-row items-center justify-center w-4 h-4 text-xs font-bold rounded-full -top-1 -right-1 bg-primary text-secondary color-black">{orderAmount}</div>
      </div>
      <div className="flex items-center justify-center w-24 rounded-full h-9 bg-secondary text-primary "> ${total}</div>
    </div>
  );
};

export default TopElements;
