import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EnumMirrors, setSoket, setSoketPos } from "../../../store/mirrors";
import { setSoketFee } from "../../../store/mirrorFee";

const Soketler = () => {
  const dispatch = useDispatch();
  const { soket, selectedMirror, soketPos } = useSelector((state) => state.mirror);

  const isDaire = selectedMirror == EnumMirrors.CHARON;

  const handleChange = (index) => {
    dispatch(setSoket(index));
    dispatch(setSoketFee(index == 1 ? 49.99 : index == 2 ? 49.99 : index == 3 ? 98 : index == 4 ? 98 : 0));
  };

  const soketOptionsArray = [
    { value: 0, label: "---" },
    { value: 1, label: "1 x chrom + 49,99 €" },
    { value: 2, label: "1 x black + 49,99 €" },
    { value: 3, label: "2 x chrom + 98,00 €" },
    { value: 4, label: "2 x black + 98,00 €" },
  ];

  //--------------SOCKET POSITION

  const soketPositionOptionsArrayForKare = [
    { value: 0, label: "Left Bottom" },
    { value: 1, label: "Right" },
    { value: 2, label: "1 x Left + 1 x Right" },
  ];

  const soketPositionOptionsArrayForDaire = [
    { value: 5, label: "Right Top" },
    { value: 6, label: "Right" },
    { value: 2, label: "Right Bottom" },
    { value: 1, label: "Bottom Center" },
    { value: 0, label: "Left Bottom" },
    { value: 7, label: "Left" },
    { value: 3, label: "Left Top" },
    { value: 4, label: "Top" },
  ];

  const soketPositionOptionsArray = isDaire ? soketPositionOptionsArrayForDaire : soketPositionOptionsArrayForKare;
  const handleChangePosition = (index) => {
    dispatch(setSoketPos(index));
  };

  return (
    <div className="flex flex-col w-full gap-4 items-center">
      <div className="flex flex-col items-center w-full gap-2">
        {soketOptionsArray.map((option) => (
          <button
            onClick={() => handleChange(option.value)}
            className={`w-full h-9 text-base rounded-full ${option.value === soket ? "bg-primary text-secondary" : "bg-secondary text-primary"} flex items-center justify-center`}
          >
            {option.label}
          </button>
        ))}
      </div>
      <h4 className="text-2xl text-secondary">Position</h4>
      <div className="flex flex-col items-center w-full gap-2">
        {soketPositionOptionsArray.map((position) => (
          <button
            onClick={() => handleChangePosition(position.value)}
            className={`w-full h-9 text-base rounded-full ${position.value === soketPos ? "bg-primary text-secondary" : "bg-secondary text-primary"} flex items-center justify-center`}
          >
            {position.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Soketler;
