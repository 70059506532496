import React from "react";
import Button from "../Common/Button";

const BotElements = ({ itemClicked, setItemClicked }) => {
  const returnBotButton = () => {
    if (itemClicked) {
      return (
        <div className="flex justify-center gap-5 mt-8" onClick={() => setItemClicked(false)}>
          <Button text="Done" className={"bg-secondary text-primary"} />
        </div>
      );
    } else {
      return (
        <div className="flex justify-center gap-5 mt-8">
          <Button text="Reset" />
          <Button text="Payment" icon={true} />
        </div>
      );
    }
  };

  return returnBotButton();
};

export default BotElements;
