import React, { useEffect, useState } from "react";
import ConfigureSection from "../Common/ConfigureSection";

import gsap from "gsap";

import { AynaTipi, Genislik, LedIsıgı, AnalogDigitalSaat, Soketler, Yukseklik, IsiSensor, AynaAdedi, Sensorler } from "../options";
import { EnumMirrors } from "../../../store/mirrors";
import { useSelector } from "react-redux";

const ConfigureContainer = ({ itemClicked, setItemClicked }) => {
  const { selectedMirror } = useSelector((state) => state.mirror);
  const [selectedMenuItemIndex, setSelectedMenuItemIndex] = useState(0);

  const isDaire = EnumMirrors.CHARON === selectedMirror;

  const menuOptions = [
    { label: isDaire ? "Diameter" : "Width", value: 0 },
    { label: "Height", value: 1 },
    { label: "Led Light", value: 2 },
    { label: "Sensors", value: 3 },
    { label: "Watch Type", value: 4 },
    { label: "Heat Sensor", value: 5 },
    { label: "Sockets", value: 6 },
    { label: "Order Amount", value: 7 },
  ];

  const selectedItem = () => {
    switch (selectedMenuItemIndex) {
      case 0:
        return <Genislik />;
      case 1:
        return <Yukseklik />;
      case 2:
        return <LedIsıgı />;
      case 3:
        return <Sensorler />;
      case 4:
        return <AnalogDigitalSaat />;
      case 5:
        return <IsiSensor />;
      case 6:
        return <Soketler />;
      case 7:
        return <AynaAdedi />;
      default:
        return null;
    }
  };

  const configuratorContainer = document.getElementById("configuratorContainer");

  const handleItemClick = (index) => {
    setSelectedMenuItemIndex(index);
    setItemClicked(true);
  };

  useEffect(() => {
    if (configuratorContainer) {
      gsap.to(configuratorContainer, {
        x: itemClicked ? -20 * 16 : 0,
        duration: 0.3,
      });
    }
  }, [itemClicked, selectedMenuItemIndex]);

  const shouldRenderHeight = (value) => {
    if (value === 2 && isDaire) {
      return false;
    }
    return true;
  };

  return (
    <div className="flex flex-col w-full gap-5 px-4 overflow-hidden !font-NeueMontreal grow">
      <div id="configuratorContainer" className="w-[38rem] h-full gap-10 configure-container overflow-hidden flex flex-row items-start justify-between">
        <div className="flex flex-col w-2/4 h-full pr-3 overflow-y-auto">
          <div className="flex flex-col w-full gap-5 configureSection">
            {menuOptions.map((option, index) => shouldRenderHeight(option.value) && <ConfigureSection index={option.value} handleItemClick={() => handleItemClick(index)} label={option.label} />)}
          </div>
        </div>
        <div className="flex flex-col items-center w-2/4 h-full gap-4 overflow-y-auto">
          <h4 className="text-2xl mr-[10px]  text-secondary">{menuOptions[selectedMenuItemIndex].label}</h4>
          {selectedItem()}
        </div>
      </div>
    </div>
  );
};

export default ConfigureContainer;
