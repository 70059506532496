import React, { useEffect, useState } from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setYataySensor, setDikeySensor, setSensors, EnumMirrors, setDikeySensorPos, setYataySensorPos } from "../../../store/mirrors";
import { setYataySensorFee, setKareSensorFee, setGecisAnahtariSensorFee } from "../../../store/mirrorFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";
const Sensorler = () => {
  const dispatch = useDispatch();
  const { yataySensor, dikeySensor, lightColorIndex, selectedMirror, yataySensorPos, dikeySensorPos } = useSelector((state) => state.mirror);
  const [selectedSensor, setSelecetedSensor] = useState(2);
  const lightSensor = lightColorIndex == 0 || lightColorIndex == 1 || lightColorIndex == 2;
  const isDaire = selectedMirror == EnumMirrors.CHARON;

  const handleChange = (index) => {
    const value = index;
    setSelecetedSensor(value);

    dispatch(setSensors(value));

    if (value == 0) {
      dispatch(setYataySensor(false));
      dispatch(setDikeySensor(false));
      dispatch(setYataySensorFee(0));
      dispatch(setKareSensorFee(0));
      dispatch(setGecisAnahtariSensorFee(0));
    }

    if (value == 1) {
      dispatch(setYataySensor(true));
      dispatch(setDikeySensor(false));
      dispatch(setYataySensorFee(59.99));
      dispatch(setKareSensorFee(0));
      dispatch(setGecisAnahtariSensorFee(0));
    }

    if (value == 2) {
      dispatch(setYataySensor(false));
      dispatch(setDikeySensor(true));
      dispatch(setYataySensorFee(0));
      dispatch(setKareSensorFee(49.99));
      dispatch(setGecisAnahtariSensorFee(0));
    }

    if (value == 3) {
      dispatch(setYataySensor(false));
      dispatch(setDikeySensor(false));
      dispatch(setGecisAnahtariSensorFee(10));
      dispatch(setYataySensorFee(0));
      dispatch(setKareSensorFee(0));
    }
  };

  useEffect(() => {
    if (selectedSensor == 0) {
      dispatch(setYataySensor(false));
      dispatch(setDikeySensor(false));
      dispatch(setYataySensorFee(0));
      dispatch(setKareSensorFee(0));
      dispatch(setGecisAnahtariSensorFee(0));
    }

    if (selectedSensor == 1) {
      dispatch(setYataySensor(true));
      dispatch(setDikeySensor(false));
      dispatch(setYataySensorFee(59.99));
      dispatch(setKareSensorFee(0));
      dispatch(setGecisAnahtariSensorFee(0));
    }

    if (selectedSensor == 2) {
      dispatch(setYataySensor(false));
      dispatch(setDikeySensor(true));
      dispatch(setYataySensorFee(0));
      dispatch(setKareSensorFee(49.99));
      dispatch(setGecisAnahtariSensorFee(0));
    }

    if (selectedSensor == 3) {
      dispatch(setGecisAnahtariSensorFee(10));
      dispatch(setKareSensorFee(0));
      dispatch(setYataySensorFee(0));
    }
  }, [selectedSensor, lightSensor]);

  ///---------------- SENSOR TYPES

  const sensorOptionsArray = [
    { value: 0, label: "---" },
    { value: 1, label: "Horizontal Sensor + 59,99 €" },
    { value: 2, label: "Touch-Sensor + 49,99 €" },
  ];

  ///---------------- VERTICAL SENSOR POSITION

  const sensorPositionOptionsArrayForDaire = [
    { value: 5, label: "Top Right" },
    { value: 6, label: "Right" },
    { value: 2, label: "Bottom Right" },
    { value: 1, label: "Bottom Center" },
    { value: 0, label: "Bottom Left" },
    { value: 7, label: "Left" },
    { value: 3, label: "Top Left" },
    { value: 4, label: "Top" },
  ];
  const sensorPositionOptionsArrayForKare = [
    { value: 0, label: "Bottom Left" },
    { value: 1, label: "Bottom Center" },
    { value: 2, label: "Bottom Right" },
  ];

  const dikeySensorPositionOptionsArray = isDaire ? sensorPositionOptionsArrayForDaire : sensorPositionOptionsArrayForKare;

  const handleChangePositionVerticalSensor = (index) => dispatch(setDikeySensorPos(index));
  const handleChangePositionHorizontalSensor = (index) => dispatch(setYataySensorPos(index));

  const handleChangePosition = (index) => {
    if (selectedSensor === 1) {
      handleChangePositionHorizontalSensor(index);
    } else if (selectedSensor === 2) {
      handleChangePositionVerticalSensor(index);
    }
  };

  const positionTitle = selectedSensor === 1 ? "Horizontal Sensor Position" : "Vertical Sensor Position";
  ///---------------- HORIZONTAL SENSOR POSITION
  const valueChecker = selectedSensor === 1 ? yataySensorPos : dikeySensorPos;
  return (
    <div className="flex flex-col w-full gap-4 items-center">
      <div className="flex flex-col items-center w-full gap-2">
        {sensorOptionsArray.map((option) => (
          <button
            onClick={() => handleChange(option.value)}
            className={`w-full h-9 text-base rounded-full ${
              (option.value === 1 && yataySensor) || (option.value === 2 && dikeySensor) ? "bg-primary text-secondary" : "bg-secondary text-primary"
            } flex items-center justify-center`}
          >
            {option.label}
          </button>
        ))}
      </div>
      <h4 className="text-2xl text-secondary">{positionTitle}</h4>
      <div className="flex flex-col items-center w-full gap-2">
        {dikeySensorPositionOptionsArray.map((position) => (
          <button
            onClick={() => handleChangePosition(position.value)}
            className={`w-full h-9 text-base rounded-full ${position.value === valueChecker ? "bg-primary text-secondary" : "bg-secondary text-primary"} flex items-center justify-center`}
          >
            {position.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Sensorler;
