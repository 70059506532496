import React, { useEffect, useState } from "react";
import { Stack, Slider, Typography, Select, MenuItem, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setIsiSensor, setIsiSensorPos, EnumMirrors } from "../../../store/mirrors";
import { setIsiSensorFee } from "../../../store/mirrorFee";
import RenderModal from "../../OrtakComponents/Modal/RenderModal";

const IsiSensor = () => {
  const dispatch = useDispatch();
  const { isiSensor, isiSensorPos, mirrorWidth, selectedMirror } = useSelector((state) => state.mirror);
  const [showError, setShowError] = useState(false);
  const [value, setValue] = useState(2);
  const isDaire = selectedMirror == EnumMirrors.CHARON || selectedMirror == EnumMirrors.DIANA || selectedMirror == EnumMirrors.HELENA;

  const handleChange = (index) => {
    if (isDaire) {
      setValue(index);
      if (mirrorWidth >= 750) {
        setShowError(false);
        dispatch(setIsiSensor(index));
        dispatch(setIsiSensorFee(index == 1 ? 79 : index == 2 ? 49.99 : index == 3 ? 69.99 : 0));
      } else if (index != 0) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    } else {
      setValue(index);
      dispatch(setIsiSensor(index == 7 || index == 8 || index == 9 || index == 0 ? 0 : 2));
      dispatch(setIsiSensorPos(index == 1 || index == 4 ? 0 : index == 2 || index == 5 ? 1 : index == 3 || index == 6 ? 2 : 0));
      dispatch(setIsiSensorFee(index == 1 || index == 2 || index == 3 ? 49.99 : index == 4 || index == 5 || index == 6 ? 69.99 : index == 7 || index == 8 || index == 9 ? 79 : 0));
    }
  };

  useEffect(() => {
    if (isDaire) {
      dispatch(setIsiSensor(value));
    }
  }, [selectedMirror]);

  const isiSensorOptionsArrayForDaire = [
    { value: 0, label: "---" },
    { value: 2, label: "Toggle Switch + €49.99" },
    { value: 3, label: "Touch-Sensor + 69,99 €" },
  ];

  const isiSensorOptionsArrayForDikdortgen = [
    { value: 0, label: "---" },
    { value: 1, label: "Touch Sensor Bottom Left + €79.00" },
    { value: 2, label: "Touch Sensor Bottom Center + €49.99" },
    { value: 3, label: "Touch Sensor Bottom Right + €69.99" },
  ];

  const isiSensorOptionsArray = isDaire ? isiSensorOptionsArrayForDaire : isiSensorOptionsArrayForDikdortgen;

  const heatSensorPositionOptionsArray = [
    { value: 9679, label: "1:30 Uhr (rechts oben)" },
    { value: 9678, label: "3:00 Uhr (rechts)" },
    { value: 9677, label: "4:30 Uhr (rechts unten)" },
    { value: 9676, label: "6:00 Uhr (unten mittig)" },
    { value: 9675, label: "7:30 Uhr (links unten)" },
    { value: 9674, label: "9:00 Uhr (links)" },
    { value: 9673, label: "10:30 Uhr (links oben)" },
    { value: 9672, label: "12:00 Uhr (oben)" },
  ];

  const handleChangeHeatSensorPositionForDaire = (index) => {
    dispatch(setIsiSensorPos(index));
  };

  return (
    <div className="flex flex-col items-center w-full gap-4">
      <div className="flex flex-col items-center w-full gap-2">
        {isiSensorOptionsArray.map((option) => (
          <button
            onClick={() => handleChange(option.value)}
            className={`w-full h-9 text-base rounded-full ${option.value === value ? "bg-primary text-secondary" : "bg-secondary text-primary"} flex items-center justify-center`}
          >
            {option.label}
          </button>
        ))}
      </div>
      {isDaire && <h4 className="text-2xl text-secondary">Heat Sensor Position</h4>}
      {isDaire && (
        <div className="flex flex-col items-center w-full gap-2">
          {heatSensorPositionOptionsArray.map((position) => (
            <button
              onClick={() => handleChangeHeatSensorPositionForDaire(position.value)}
              className={`w-full h-9 text-base rounded-full ${position.value === isiSensorPos ? "bg-primary text-secondary" : "bg-secondary text-primary"} flex items-center justify-center`}
            >
              {position.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default IsiSensor;
