import { Suspense, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { useSelector } from 'react-redux';
import { OrbitControls, Sky } from '@react-three/drei';
import { Provider } from 'react-redux';
import store from '../store/index';
import Room from './OrtakComponents/Room';
import { DaireAyna, KareAyna } from './AynaComponents';
import { useMediaQuery } from '@mui/material';
import { Bloom, EffectComposer } from '@react-three/postprocessing';
import { UnrealBloomPass } from 'three-stdlib';
import { extend } from '@react-three/fiber';
import { EnumMirrors } from '../store/mirrors';

import NewMenu from './NewMenu/NewMenu';
import AynaTipi from './AynaComponents/AynaTipi';

extend({ UnrealBloomPass });

const AynaCanvas = () => {
  const { selectedMirror, lightIsOpen, doubleLed, isMenuOpen } = useSelector((state) => state.mirror);
  const desktopMatches1700 = useMediaQuery('(min-width:1700px)');
  const desktopMatches1200 = useMediaQuery('(min-width:1200px)');
  const mobileMatches600 = useMediaQuery('(min-width:600px)');

  const canvasResponsiveWidth = desktopMatches1700 ? '100vw' : desktopMatches1200 ? '100vw' : mobileMatches600 && isMenuOpen ? '50vw' : '100vw';
  const isPaintedMirror = [EnumMirrors.F620TL, EnumMirrors.F621TL, EnumMirrors.F622TL, EnumMirrors.F623TL].includes(selectedMirror);
  const Kristallspiegel3_5mm = [EnumMirrors.STARK_RAHMENLOS_5MM, EnumMirrors.SILBER_RAHMENLOS_3MM].includes(selectedMirror);
  const isKristall = [EnumMirrors.STARK_RAHMENLOS_5MM, EnumMirrors.SILBER_RAHMENLOS_3MM, EnumMirrors.RAHMENLOSER_6_MM].includes(selectedMirror);
  const isOval = [EnumMirrors.F597L4K, EnumMirrors.F610L4K].includes(selectedMirror);
  const isWunschdekor = [EnumMirrors.WUNSCHDEKOR].includes(selectedMirror);

  const [menuButtonIsActive, setMenuButtonIsActive] = useState(false);
  const [productsMenuButtonIsActive, setProductsMenuButtonIsActive] = useState(false);

  return (
    <>
      <Canvas
        className="canvasHeightEdit"
        colorManagement
        shadowMap
        style={{
          width: '100vw',
          height: `100vh`,
          float: 'right',
          backgroundColor: '#e3e3e3',
          position: 'relative',
        }}
        dpr={[1, 1.5]}
        shadows
        camera={{ position: [0, 0, 7], fov: 60, zoom: 0.9 }}
      >
        {lightIsOpen && (
          <EffectComposer multisampling={4} disableNormalPass>
            <Bloom luminanceThreshold={1} intensity={doubleLed ? 0.03 : 0.015} levels={2} />
            <Bloom luminanceThreshold={1} intensity={doubleLed ? 0.007 : 0.0053} levels={5} mipmapBlur />
            <Bloom luminanceThreshold={1} intensity={doubleLed ? 0.003 : 0.0019} levels={6} mipmapBlur />
          </EffectComposer>
        )}
        <Provider store={store}>
          <Room />
          <ambientLight intensity={0.5} color={'#f5edda'} />
          <pointLight position={[0, 5, 0]} intensity={0.5} color={'#f5edda'} />
          <Sky sunPosition={[0, 5, 0]} />
          <OrbitControls
            minPolarAngle={Math.PI / 2.8}
            maxPolarAngle={Math.PI / 2.1}
            minAzimuthAngle={Math.PI * 1.85}
            maxAzimuthAngle={Math.PI * 2.2}
            zoomSpeed={0.25}
            enableZoom={true}
            makeDefault
            maxDistance={9}
            minDistance={2}
            enablePan={false}
          />
          <Suspense fallback={null}>
            {selectedMirror === EnumMirrors.CHARON ? <DaireAyna /> : selectedMirror === EnumMirrors.NEW_JERSEY || isPaintedMirror || isKristall || isWunschdekor ? <KareAyna /> : null}
          </Suspense>
        </Provider>
      </Canvas>
      <AynaTipi setMenuButtonIsActive={setMenuButtonIsActive} setProductsMenuButtonIsActive={setProductsMenuButtonIsActive} productsMenuButtonIsActive={productsMenuButtonIsActive} />
      <NewMenu setMenuButtonIsActive={setMenuButtonIsActive} setProductsMenuButtonIsActive={setProductsMenuButtonIsActive} menuButtonIsActive={menuButtonIsActive} />
    </>
  );
};

export default AynaCanvas;
