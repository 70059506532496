import { useEffect } from "react";

import AynaCanvas from "./newComponents/AynaCanvas";

import { EnumMirrors, changeLightThickness, changeSelectedExcelItem, setSquareMirrorSelectedItem, changeMirror, changePrivateStatus, changeMenuIsOpen } from "./store/mirrors";

import { useDispatch, useSelector } from "react-redux";

import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";

export const App = () => {
  const dispatch = useDispatch();

  function getKeyByValue(value) {
    const Enums = [EnumMirrors];

    for (let i = 0; i < Enums.length; i++) {
      let indexOfS = Object.values(Enums[i]).indexOf(Number(value));
      let key = Object.keys(Enums[i])[indexOfS];

      if (key) {
        return key;
      }
    }
  }

  const mobileMatches600 = useMediaQuery("(max-width:600px)");

  const { selectedMirror } = useSelector((state) => state.mirror);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const productId = urlParams.get("products_id");
    if (productId) {
      let mirrorEnumName = getKeyByValue(productId);

      if (EnumMirrors[mirrorEnumName]) {
        switch (EnumMirrors[mirrorEnumName]) {
          case EnumMirrors.PLUTO_2:
            dispatch(changeMirror(EnumMirrors.CHARON));
            dispatch(changeLightThickness(0.88));
            dispatch(
              changeSelectedExcelItem({
                name: "Pluto 2",
                id: EnumMirrors.PLUTO_2,
              })
            );
            break;
          case EnumMirrors.CHARON:
            dispatch(changeMirror(EnumMirrors.CHARON));
            dispatch(changeLightThickness(1));
            dispatch(
              changeSelectedExcelItem({
                name: "Charon",
                id: EnumMirrors.CHARON,
              })
            );
            break;
          case EnumMirrors.HELENA:
            dispatch(changeMirror(EnumMirrors.CHARON));
            dispatch(changeLightThickness(0.97));
            dispatch(
              changeSelectedExcelItem({
                name: "Helena",
                id: EnumMirrors.HELENA,
              })
            );
            break;
          case EnumMirrors.DIANA:
            dispatch(changeMirror(EnumMirrors.CHARON));
            dispatch(changeLightThickness(0.9));
            dispatch(
              changeSelectedExcelItem({
                name: "Diana",
                id: EnumMirrors.DIANA,
              })
            );
            break;
          case EnumMirrors.RAHMENLOSER_6_MM:
            dispatch(changeMirror(EnumMirrors.RAHMENLOSER_6_MM));
            dispatch(setSquareMirrorSelectedItem("Kristallspiegel"));
            dispatch(
              changeSelectedExcelItem({
                name: "RAHMENLOSER 6 MM",
                id: EnumMirrors.RAHMENLOSER_6_MM,
              })
            );
            break;
          case EnumMirrors.STARK_RAHMENLOS_5MM:
            dispatch(changeMirror(EnumMirrors.STARK_RAHMENLOS_5MM));
            dispatch(setSquareMirrorSelectedItem("Kristallspiegel"));
            dispatch(
              changeSelectedExcelItem({
                name: "STARK RAHMENLOS 5MM",
                id: EnumMirrors.STARK_RAHMENLOS_5MM,
              })
            );
            break;
          case EnumMirrors.SILBER_RAHMENLOS_3MM:
            dispatch(changeMirror(EnumMirrors.SILBER_RAHMENLOS_3MM));
            dispatch(setSquareMirrorSelectedItem("Kristallspiegel"));
            dispatch(
              changeSelectedExcelItem({
                name: "SILBER RAHMENLOS 3MM",
                id: EnumMirrors.SILBER_RAHMENLOS_3MM,
              })
            );
            break;
          case EnumMirrors.NEW_JERSEY:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Arka"));
            dispatch(
              changeSelectedExcelItem({
                name: "New Jersey",
                id: EnumMirrors.NEW_JERSEY,
              })
            );
            break;
          case EnumMirrors.NEW_YORK:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Full_+_Dış"));
            dispatch(
              changeSelectedExcelItem({
                name: "New York",
                id: EnumMirrors.NEW_YORK,
              })
            );
            break;
          case EnumMirrors.BOLNUEVO:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Full_+_İnce"));
            dispatch(
              changeSelectedExcelItem({
                name: "Bolnuevo",
                id: EnumMirrors.BOLNUEVO,
              })
            );
            break;
          case EnumMirrors.PORTAU:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Full_+_Kalın"));
            dispatch(
              changeSelectedExcelItem({
                name: "Portau",
                id: EnumMirrors.PORTAU,
              })
            );
            break;
          case EnumMirrors.NAMUS:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Üst"));
            dispatch(
              changeSelectedExcelItem({
                name: "Namus",
                id: EnumMirrors.NAMUS,
              })
            );
            break;
          case EnumMirrors.M18L2V:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Sağ_+_Sol_+_Uzun"));
            dispatch(
              changeSelectedExcelItem({
                name: "M18L2V",
                id: EnumMirrors.M18L2V,
              })
            );
            break;
          case EnumMirrors.MIAMI:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Alt_+_Üst"));
            dispatch(
              changeSelectedExcelItem({
                name: "Miami",
                id: EnumMirrors.MIAMI,
              })
            );
            break;
          case EnumMirrors.M01L2V:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Sağ_+_Sol"));
            dispatch(
              changeSelectedExcelItem({
                name: "M01L2V",
                id: EnumMirrors.M01L2V,
              })
            );
            break;
          case EnumMirrors.SANTA_CRUZ:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Sağ_+_Sol_+_Üst_+_Dış"));
            dispatch(
              changeSelectedExcelItem({
                name: "Santa Cruz",
                id: EnumMirrors.SANTA_CRUZ,
              })
            );
            break;
          case EnumMirrors.GREEN_BAY:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Sağ_+_Üst_+_Sol"));
            dispatch(
              changeSelectedExcelItem({
                name: "Green Bay",
                id: EnumMirrors.GREEN_BAY,
              })
            );
            break;
          case EnumMirrors.AUROA:
            dispatch(changeMirror(122));
            dispatch(setSquareMirrorSelectedItem("Full_+_İnce_+_İç"));
            dispatch(
              changeSelectedExcelItem({
                name: "Aurora",
                id: EnumMirrors.AUROA,
              })
            );
            break;
          case EnumMirrors.F620TL:
            dispatch(changeMirror(EnumMirrors.F620TL));
            dispatch(
              changeSelectedExcelItem({
                name: "F620TL",
                id: EnumMirrors.F620TL,
              })
            );
            break;
          case EnumMirrors.F621TL:
            dispatch(changeMirror(EnumMirrors.F621TL));
            dispatch(
              changeSelectedExcelItem({
                name: "F621TL",
                id: EnumMirrors.F621TL,
              })
            );
            break;
          case EnumMirrors.F622TL:
            dispatch(changeMirror(EnumMirrors.F622TL));
            dispatch(
              changeSelectedExcelItem({
                name: "F622TL",
                id: EnumMirrors.F622TL,
              })
            );
            break;
          case EnumMirrors.F623TL:
            dispatch(changeMirror(EnumMirrors.F623TL));
            dispatch(
              changeSelectedExcelItem({
                name: "F623TL",
                id: EnumMirrors.F623TL,
              })
            );
            break;
          case EnumMirrors.F610L4K:
            dispatch(changeMirror(EnumMirrors.F610L4K));
            dispatch(
              changeSelectedExcelItem({
                name: "F610L4K",
                id: EnumMirrors.F610L4K,
              })
            );
            break;
          case EnumMirrors.F597L4K:
            dispatch(changeMirror(EnumMirrors.F597L4K));
            dispatch(
              changeSelectedExcelItem({
                name: "F597L4K",
                id: EnumMirrors.F597L4K,
              })
            );
            break;
          case EnumMirrors.WUNSCHDEKOR:
            dispatch(changeMirror(EnumMirrors.WUNSCHDEKOR));
            dispatch(
              changeSelectedExcelItem({
                name: "WUNSCHDEKOR",
                id: EnumMirrors.WUNSCHDEKOR,
              })
            );
            break;
        }
      }
    } else {
      dispatch(changePrivateStatus(false));
    }
  }, []);

  useEffect(() => {
    if (mobileMatches600) {
      dispatch(changeMenuIsOpen(true));
    }
  }, [selectedMirror]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#0d646f",
        // light: will be calculated from palette.primary.main,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div id="masterContainerDiv" className="w-full  h-100vh flex">
        <AynaCanvas />
      </div>
    </ThemeProvider>
  );
};
