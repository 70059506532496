import React from "react";
import { useSelector } from "react-redux";
import { EnumMirrors } from "../../../store/mirrors";

const ProductName = () => {
  const { selectedMirror } = useSelector((state) => state.mirror);
  const selectedMirrorName = selectedMirror === EnumMirrors.CHARON ? "CHARON" : "NEW JERSEY";
  // https://www.spiegel21.de/images/product_images/info_images/${cabinet.value}_0.jpg
  return (
    <div className="flex flex-col items-center">
      <h4 className=" text-[2rem]">{selectedMirrorName}</h4>
    </div>
  );
};

export default ProductName;
