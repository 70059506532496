import React from "react";

const Icon = ({ src, className, imageClass }) => {
  return (
    <div className={`${className} w-9 h-9 p-[9px] rounded-full bg-secondary cursor-pointer`}>
      <img src={src} className={`${imageClass}`} />
    </div>
  );
};

export default Icon;
