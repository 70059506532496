import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeMirrorHeight } from "../../../store/mirrors";

const Yukseklik = () => {
  const { selectedMirror, selectedMirrorExcelIndex } = useSelector((state) => state.mirror);
  const dispatch = useDispatch();

  const [alertMessage, setAlertMessage] = useState(null);
  const [input, setInput] = useState(1000);

  const MAX_VALUE = 1500;
  const MIN_VALUE = 400;

  useEffect(() => {
    setInput(1000);
    dispatch(changeMirrorHeight(1000));
  }, [selectedMirror, selectedMirrorExcelIndex]);

  const handleChange = (e) => {
    setInput(e.target.value);
    if (e.target.value < MIN_VALUE || e.target.value > MAX_VALUE) {
      setAlertMessage(true);
    } else {
      setAlertMessage(false);
      dispatch(changeMirrorHeight(e.target.value));
    }
  };

  return (
    <>
      <div>
        <input className="text-center text-lg bg-transparent border-b outline-none focus:bg-transparent border-secondary" type="number" value={input} onChange={handleChange} />
      </div>
      {alertMessage ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{
            position: "relative",
            top: -10,
          }}
        >
          <small
            style={{
              color: "red",
            }}
          >
            Please enter the height value between {MIN_VALUE} and {MAX_VALUE}
          </small>
        </Stack>
      ) : null}
    </>
  );
};

export default Yukseklik;
