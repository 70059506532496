import { useDispatch, useSelector } from "react-redux";
import { changeLightColor, setDisableSensors } from "../../../store/mirrors";
import { setLedIsigiFee } from "../../../store/mirrorFee";

const LedIsıgı = () => {
  const dispatch = useDispatch();
  const { lightColorIndex } = useSelector((state) => state.mirror);
  const handleChange = (index) => {
    dispatch(changeLightColor(index));
    dispatch(setLedIsigiFee(index == 3 ? 79.99 : index == 4 ? 99 : index == 5 ? 179.99 : index == 6 ? 159.99 : 0));
    if (index == 3 || index == 4 || index == 5 || index == 6) {
      dispatch(setDisableSensors());
    }
  };

  const ledIsigiOptionsArray = [
    { value: 0, label: "Neutral White" },
    { value: 1, label: "Warm" },
    { value: 2, label: "Cold" },
  ];

  return (
    <div className="flex flex-col items-center w-full gap-2">
      {ledIsigiOptionsArray.map((option) => (
        <button
          onClick={() => handleChange(option.value)}
          className={`w-full h-9 text-base rounded-full ${option.value === lightColorIndex ? "bg-primary text-secondary" : "bg-secondary text-primary"} flex items-center justify-center`}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default LedIsıgı;
