import { configureStore } from "@reduxjs/toolkit";

import mirrorReducer from "./mirrors";

import utilsReducer from "./utils";

import mirrorFeeReducer from "./mirrorFee";

const store = configureStore({
  reducer: {
    mirror: mirrorReducer,
    utils: utilsReducer,
    mirrorFee: mirrorFeeReducer,
  },
});

export default store;
