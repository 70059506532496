import React from "react";
import { useState } from "react";

import { Kare } from "./Mirrors/Kare";

const KareAyna = () => {
  const [showPandora, setShowPandora] = useState();
  const [showPandoraLight, setShowPandoraLight] = useState();

  const showPandoraHandler = (info) => {
    setShowPandora(info);
  };

  const pandoraLightHandler = (info) => {
    setShowPandoraLight(info);
  };

  return (
    <>
      <Kare showPandoraHandler={showPandoraHandler} pandoraLightHandler={pandoraLightHandler} />
    </>
  );
};

export default KareAyna;
