import React, { useRef, useEffect, useState } from "react";
import { MeshReflectorMaterial, useGLTF } from "@react-three/drei";
import { useDispatch, useSelector } from "react-redux";
import { changeKareAynaModel, EnumMirrors } from "../../../store/mirrors";
import CentimeterHeightLines from "../../OrtakComponents/CM/CentimeterHeightLines";
import CentimeterWidthLines from "../../OrtakComponents/CM/CentimeterWidthLines";
import CamRaf from "./KareAynaComponents/CamRaf";
import Wunschdekor from "./KareAynaComponents/Wunschdekor";
import ObjelerLeft from "./KareAynaComponents/objeler/ObjelerLeft";
import ObjelerMid from "./KareAynaComponents/objeler/ObjelerMid";
import ObjelerRight from "./KareAynaComponents/objeler/ObjelerRight";
import * as THREE from "three";

export function Kare(props) {
  const { nodes, materials } = useGLTF("/rectangle-mirror-rev01.glb");
  const rectangle = useRef();
  const buzluCam = useRef();
  const dispatch = useDispatch();

  const {
    lightColor,
    lightIsOpen,
    mirrorWidth,
    mirrorHeight,
    selectedDisableMirrors,
    analogWatch,
    digitalWatch,
    heightCmVisible,
    widthCmVisible,
    squareMirrorSelectedItem,
    makeupMirrorPos,
    makyajAynasiYandanUzaklik,
    makyajAynasiAlttanUzaklik,
    raf,
    selectedMirror,
  } = useSelector((state) => state.mirror);

  const isWunschdekor = [EnumMirrors.WUNSCHDEKOR].includes(selectedMirror);
  const [backLightShow, setBacklightShow] = useState(true);

  const visibleSelectedItems = [
    "siyah_çerçeve-kare",
    "dijitalSAAT-kare",
    "AKREP_YELKOVAN-kare",
    "sensor-yatay",
    "sensor-kare",
    "pleksi_ışık001-kare",
    "PLESİ_IŞIK_METAL-kare",
    "Alt_+_Üst",
    "Sağ_+_Sol",
    "Sağ_+_Üst_+_Sol",
    "Üst",
    "Sağ_+_Sol_+_Uzun",
    "Sağ_+_Sol_+_Üst_+_Dış",
    "Full_+_Dış",
    "Full_+_İnce",
    "Full_+_Kalın",
    "Full_+_İnce_+_İç",
    "Arka",
    "Kristallspiegel",
  ];

  useEffect(() => {
    visibleSelectedItems.map((item) => {
      rectangle.current.children.map((child) => {
        if (child.name === item) {
          child.visible = false;
        }
      });
    });
  }, []);

  useEffect(() => {
    if (selectedDisableMirrors.length > 0) {
      selectedDisableMirrors.map((mesh) => {
        rectangle.current.children.map((child) => {
          if (child.name === mesh.name) {
            child.visible = mesh.isVisible;
          }
        });
      });
    }
  }, [selectedDisableMirrors]);

  useEffect(() => {
    if (lightIsOpen) {
      props.pandoraLightHandler(true);
    } else {
      props.pandoraLightHandler(false);
    }
  }, [lightIsOpen]);

  function disableItem(name, element) {
    const items = [
      "Pandora",
      "Üst",
      "Sağ_+_Sol_+_Uzun",
      "Alt_+_Üst",
      "Sağ_+_Sol",
      "Sağ_+_Sol_+_Üst_+_Dış",
      "Sağ_+_Üst_+_Sol",
      "Full_+_Dış",
      "Full_+_İnce",
      "Full_+_Kalın",
      "Full_+_İnce_+_İç",
      "Arka",
      "Kristallspiegel",
    ];

    if (name === "Pandora") {
      props.showPandoraHandler(true);
    } else {
      props.showPandoraHandler(false);
    }

    if (name === "Arka") {
      setBacklightShow(true);
    } else {
      setBacklightShow(false);
    }

    rectangle.current.children.forEach((child) => {
      if (items.includes(child.name)) {
        child.visible = false;
      }
      if (child.name === name) {
        child.visible = true;
        dispatch(changeKareAynaModel(name));
      }
    });
  }

  useEffect(() => {
    disableItem(squareMirrorSelectedItem);
  }, [squareMirrorSelectedItem]);

  useEffect(() => {
    if (buzluCam.current) {
      buzluCam.current.material.roughness = 0.25;
    }
  });

  const scaleMultiplier = 0.6; // Kare aynadaki scale hatasını düzeltmek için

  // Positions
  const leftLimit = useRef(null);
  const rightLimit = useRef(null);
  const topLimit = useRef(null);
  const bottomLimit = useRef(null);

  useEffect(() => {
    const objectsAreHere = leftLimit.current && rightLimit.current && topLimit.current && bottomLimit.current;

    if (objectsAreHere) {
      const limitLeft = leftLimit.current.position.x;
      const limitRight = rightLimit.current.position.x;
      const limitTop = topLimit.current.position.y;
      const limitBottom = bottomLimit.current.position.y;

      let makX;
      let makY;
      let makZ = 0.09;

      const degisimX = (makyajAynasiYandanUzaklik - 180) / (mirrorWidth - 360);
      const degisimY = (makyajAynasiAlttanUzaklik - 180) / (mirrorHeight - 360);
      if (makeupMirrorPos == 1) {
        makX = limitLeft + degisimX * (limitRight - limitLeft);
        if (makX > limitRight) {
          makX = limitRight;
        }
        makY = limitBottom + degisimY * (limitTop - limitBottom);
        if (makY > limitTop) {
          makY = limitTop;
        }
      } else {
        makX = limitRight - degisimX * (limitRight - limitLeft);
        if (makX < limitLeft) {
          makX = limitLeft;
        }
        makY = limitBottom + degisimY * (limitTop - limitBottom);
        if (makY > limitTop) {
          makY = limitTop;
        }
      }
      // setMakyajPosition([makX - 0.1, makY + 0.155, makZ]);
    }
  }, [mirrorWidth, mirrorHeight, makyajAynasiYandanUzaklik, makyajAynasiAlttanUzaklik, makeupMirrorPos, analogWatch, digitalWatch, raf]);

  return (
    <>
      {widthCmVisible && (
        <CentimeterWidthLines
          scale={[0.118, 0.3, 0.15]}
          textWidth={mirrorWidth > 999 ? "150px" : "120x"}
          textPadding={mirrorWidth > 999 ? "0 21px" : "0 35px"}
          textValue={mirrorWidth === 0 ? "400" : mirrorWidth}
          textSize={"3em"}
          textHeight="75px"
          lineLeft={-55 - mirrorWidth * 0.435}
          lineWidth={0 + mirrorWidth * 0.9}
          position={[0, -0.95 + mirrorHeight * 0.0016, 0.2]}
        />
      )}
      {heightCmVisible && (
        <CentimeterHeightLines
          scale={[0.3, 0.12, 0.15]}
          textWidth={mirrorWidth > 999 ? "150px" : "120x"}
          textPadding={mirrorHeight > 999 ? "0 21px" : "0 35px"}
          textValue={mirrorHeight == 0 ? "400" : mirrorHeight}
          textSize={"3em"}
          textPos={[0.52, 0.6 - mirrorHeight * 0.0003, 0.02]}
          textHeight="75px"
          textTop={160 - mirrorHeight * 0.22}
          lineTop={110 - mirrorHeight * 0.48}
          lineHeight={-15 + mirrorHeight * 0.81}
          position={[-0.2 + mirrorWidth * 0.0014, -0.3, 0.2]}
        />
      )}
      {/* MAKYAJ AYNASI LIMITLERI */}
      <group>
        {/* LEFT */}
        <mesh ref={leftLimit} position={[-0.18 - ((mirrorWidth - 400) / 400) * 0.5, 0, 0.1]} scale={2}>
          <meshBasicMaterial transparent={true} opacity={0} color={"red"} />
          <boxGeometry args={[0.01, 0.3, 0.01]} />
        </mesh>
        {/* RIGHT */}
        <mesh ref={rightLimit} position={[0.2 + ((mirrorWidth - 400) / 400) * 0.54, 0, 0.1]} scale={2}>
          <meshBasicMaterial transparent={true} opacity={0} color={"red"} />
          <boxGeometry args={[0.01, 0.3, 0.01]} />
        </mesh>
        {/* TOP */}
        <mesh ref={topLimit} position={[0, 0.08 + ((mirrorHeight - 400) / 400 / 0.45) * 0.3, 0.1]} scale={2}>
          <meshBasicMaterial transparent={true} opacity={0} color={"red"} />
          <boxGeometry args={[0.7, 0.01, 0.01]} />
        </mesh>
        {/* BOTTOM */}
        <mesh ref={bottomLimit} position={[0, (raf ? 0.17 : 0.11) - ((mirrorHeight - 400) / 400) * 0.7 * 0.18, 0.1]} scale={2}>
          <meshBasicMaterial transparent={true} opacity={0} color={"red"} />
          <boxGeometry args={[0.7, 0.01, 0.01]} />
        </mesh>
      </group>

      <ObjelerLeft
        position={[0.13 - mirrorWidth * 0.0013 + mirrorHeight * 0.0001, raf ? -0.135 - mirrorHeight * 0.000395 + mirrorWidth * 0.00006 : -0.2 - mirrorHeight * 0.000395 + mirrorWidth * 0.00006, 0.2]}
      />
      <ObjelerMid position={[0, raf ? -0.135 - mirrorHeight * 0.000395 + mirrorWidth * 0.00006 : -0.2 - mirrorHeight * 0.000395 + mirrorWidth * 0.00006, 0.2]} />
      <ObjelerRight
        position={[-0.13 + mirrorWidth * 0.0013 - mirrorHeight * 0.0001, raf ? -0.135 - mirrorHeight * 0.000395 + mirrorWidth * 0.00006 : -0.2 - mirrorHeight * 0.000395 + mirrorWidth * 0.00006, 0.2]}
      />

      {/* ----- */}
      {isWunschdekor && <Wunschdekor />}
      <group
        ref={rectangle}
        {...props}
        position={[0, -0.2 - mirrorHeight * 0.0012, 0.2]}
        rotation={[0, 0, 0]}
        scale={[((3.5 * mirrorWidth) / 400) * 0.4, ((3.3 * mirrorHeight) / 400) * 0.4, 1.6 * 0.5]}
        dispose={null}
      >
        {/* Cam raf */}
        <CamRaf buzluCam={buzluCam} />
        {/* AYNA TIPLERI */}
        <>
          <group name="newjersey" position={[0.01, 0.43, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[1 * scaleMultiplier, 1, 1]}>
            <mesh material={materials.glass} rotation-x={Math.PI * -0.5}>
              <planeGeometry args={[1.28, 0.73]} />
            </mesh>
            <mesh renderOrder={1} position-y={0.02} rotation-x={Math.PI * -0.5}>
              <planeGeometry args={[1.28, 0.73]} />
              <MeshReflectorMaterial side={THREE.DoubleSide} resolution={1024} blur={0} mixBlur={0} mirror={1} />
            </mesh>
            <mesh material={materials.glaze} rotation-x={Math.PI * -0.5}>
              <planeGeometry args={[1.28, 0.73]} />
            </mesh>
          </group>
          <group name="Kristallspiegel" position={[0.01, 0.43, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[1 * scaleMultiplier, 1, 1]}>
            <mesh geometry={nodes.New_Jersey_1.geometry} material={materials.glass}></mesh>
            <mesh geometry={nodes.New_Jersey_2.geometry} renderOrder={-1} material={materials.mirror} />

            <mesh geometry={nodes.New_Jersey_3.geometry} material={materials.glaze} />
          </group>
          <mesh name="Üst" geometry={nodes.Namus.geometry} material={materials.ledlightTexture} position={[0.01, 0.74, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[1 * scaleMultiplier, 30, 1]}>
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>

          {backLightShow && (
            <mesh geometry={nodes.Namus.geometry} material={materials.ledlightTexture} position={[0.01, 0.43, -0.01]} rotation={[Math.PI / 2, 0, 0]} scale={[1.17 * scaleMultiplier, 30, 16.6]}>
              <meshBasicMaterial color={lightColor} toneMapped={false} />
            </mesh>
          )}

          <mesh position={[0.01, 0.43, -0.03]} rotation={[Math.PI / 2, 0, 0]} scale={[1 * scaleMultiplier, 1, 1]}>
            <boxGeometry args={[1.27, 0.05, 0.73]} />
            <meshBasicMaterial color={"#666666"} />
          </mesh>
          <mesh
            name="Sağ_+_Sol_+_Uzun"
            geometry={nodes.M18L2V.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.43, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[1 * scaleMultiplier, 30.01, 1]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Alt_+_Üst"
            geometry={nodes.Miami.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.425, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[1 * scaleMultiplier, 29.91, 1]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Sağ_+_Sol"
            geometry={nodes.M01L2V.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.43, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[1 * scaleMultiplier, 30.14, 1]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Sağ_+_Sol_+_Üst_+_Dış"
            geometry={nodes.Santa_Cruz.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.44, 0]}
            rotation={[Math.PI / 2, Math.PI / 2, 0]}
            scale={[1.67 * scaleMultiplier, 0.1, 0.6]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Sağ_+_Üst_+_Sol"
            geometry={nodes.Green_Bay.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.42, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[1 * scaleMultiplier, 29.87, 1]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Full_+_Dış"
            geometry={nodes.Newyork.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.43, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[1.08 * scaleMultiplier, 1.08, 1.08]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Full_+_İnce"
            geometry={nodes.Bolnuevo.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.43, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[1 * scaleMultiplier, 3.61, 1]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Full_+_Kalın"
            geometry={nodes.Portau.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.43, 0]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.84 * scaleMultiplier, 25.14, 0.84]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Full_+_İnce_+_İç"
            geometry={nodes.Aurora.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.43, 0.01]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[1 * scaleMultiplier, 1.21, 1]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
          <mesh
            name="Arka"
            geometry={nodes.New_Jersey_backlight.geometry}
            material={materials.ledlightTexture}
            position={[0.01, 0.43, -0.02]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.98 * scaleMultiplier, 1, 0.98]}
          >
            <meshBasicMaterial color={lightColor} toneMapped={false} />
          </mesh>
        </>
      </group>
    </>
  );
}

useGLTF.preload("/rectangle-mirror-rev01.glb");
