import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EnumMirrors, setAnalogWatch, setDigitalWatch, setWatchPos } from "../../../store/mirrors";
import { setSaatFee } from "../../../store/mirrorFee";

const AnalogDigitalSaat = () => {
  const dispatch = useDispatch();
  const { digitalWatch, analogWatch, selectedMirror, watchPos } = useSelector((state) => state.mirror);
  //---- WATCH TYPE
  const optionsArray = [
    { value: 0, label: "---" },
    { value: 1, label: "Digital with app sync + 49,99 €" },
    { value: 2, label: "Analog + 49,99 €" },
  ];

  const handleChange = (index) => {
    if (index == 1) {
      dispatch(setDigitalWatch(true));
      dispatch(setAnalogWatch(false));
    } else if (index == 2) {
      dispatch(setAnalogWatch(true));
      dispatch(setDigitalWatch(false));
    } else {
      dispatch(setAnalogWatch(false));
      dispatch(setDigitalWatch(false));
    }

    dispatch(setSaatFee(index == 0 ? 0 : 49.99));
  };
  ///----- WATCH POSITION

  const isDaire = selectedMirror == EnumMirrors.CHARON || selectedMirror == EnumMirrors.DIANA || selectedMirror == EnumMirrors.HELENA;

  const optionsArrayKare = [
    { value: 0, label: "Bottom Left" },
    { value: 1, label: "Bottom Center" },
    { value: 2, label: "Bottom Right" },
    { value: 3, label: "Top Left" },
    { value: 4, label: "Top Center" },
    { value: 5, label: "Top Right" },
  ];

  const optionsArrayDaire = [
    { value: 5, label: "Top Right" },
    { value: 6, label: "Right" },
    { value: 2, label: "Bottom Right" },
    { value: 1, label: "Bottom Center" },
    { value: 0, label: "Bottom Left" },
    { value: 7, label: "Left" },
    { value: 3, label: "Top Left" },
    { value: 4, label: "Top" },
  ];

  const optionsArrayPosition = isDaire ? optionsArrayDaire : optionsArrayKare;

  const handleChangePosition = (index) => {
    dispatch(setWatchPos(index));
  };

  return (
    <div className="flex flex-col w-full gap-4 items-center">
      <div className="flex flex-col items-center w-full gap-2">
        {optionsArray.map((option) => (
          <button
            onClick={() => handleChange(option.value)}
            className={`w-full h-9 text-base rounded-full ${
              (option.value === 1 && digitalWatch) || (option.value === 2 && analogWatch) ? "bg-primary text-secondary" : "bg-secondary text-primary"
            } flex items-center justify-center`}
          >
            {option.label}
          </button>
        ))}
      </div>
      <h4 className="text-2xl text-secondary">Position</h4>
      <div className="flex flex-col items-center w-full gap-2">
        {optionsArrayPosition.map((position) => (
          <button
            onClick={() => handleChangePosition(position.value)}
            className={`w-full h-9 text-base rounded-full ${position.value === watchPos ? "bg-primary text-secondary" : "bg-secondary text-primary"} flex items-center justify-center`}
          >
            {position.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AnalogDigitalSaat;
