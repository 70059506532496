import React from "react";

const Button = ({ text, className, icon = false }) => {
  return (
    <button className={`${className} w-36 h-10 text-lg rounded-full bg-secondary text-primary flex items-center justify-center`}>
      {text}
      {icon && (
        <svg className="ml-2" width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.53128 0.838745L13.9136 6.33876M13.9136 6.33876L7.53128 11.8387M13.9136 6.33876H1.18945" stroke="#F6F6F6" stroke-linecap="square" />
        </svg>
      )}
    </button>
  );
};

export default Button;
