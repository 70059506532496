import { useDispatch, useSelector } from "react-redux";

import { changeMirror, resetToInitialState, setSquareMirrorSelectedItem } from "../../store/mirrors";
import { EnumMirrors, changeSelectedExcelItem } from "../../store/mirrors";
import { Avatar, useMediaQuery } from "@mui/material";

const AynaTipi = ({ setMenuButtonIsActive, setProductsMenuButtonIsActive, productsMenuButtonIsActive }) => {
  const dispatch = useDispatch();
  const { selectedMirror } = useSelector((state) => state.mirror);

  const isDaire = selectedMirror === EnumMirrors.CHARON;

  const mirrors = [
    {
      name: "Charon",
      value: EnumMirrors.CHARON,
      excelId: EnumMirrors.CHARON,
    },
    {
      name: "New Jersey",
      value: EnumMirrors.NEW_JERSEY,
      excelId: EnumMirrors.NEW_JERSEY,
    },
  ];

  const handleDoorSelection = ({ name, value, excelId }) => {
    dispatch(resetToInitialState());
    dispatch(changeMirror(value));
    // dispatch(setSquareMirrorSelectedItem("Arka"));
  };

  const menuMobileMatches = useMediaQuery("(max-width:704px)");

  return (
    <>
      {menuMobileMatches && !productsMenuButtonIsActive ? (
        <div
          className="absolute flex items-center left-10 top-14"
          onClick={() => {
            setProductsMenuButtonIsActive(true);
            setMenuButtonIsActive(false);
          }}>
          <div class="stroke-primary !fill-primary bg-secondary p-2 rounded-full  cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke=""
              className="w-6 h-6">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
              />
            </svg>
          </div>
        </div>
      ) : (
        <div className="absolute flex items-center left-10 top-14">
          <div className="flex flex-col items-center gap-8 px-10 py-8 bg-opacity-50 bg-secondary backdrop-blur-md rounded-3xl font-NeueMontreal">
            {/* Close button */}
            {menuMobileMatches ? (
              <div
                className="flex self-end"
                onClick={() => setProductsMenuButtonIsActive(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>
            ) : null}
            {/* Close button end */}

            {mirrors.map((mirror, index) => (
              <ClosetButton
                mirror={mirror}
                handleDoorSelection={handleDoorSelection}
                index={index}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default AynaTipi;

const ClosetButton = ({ mirror, handleDoorSelection, index }) => {
  const { selectedMirror } = useSelector((state) => state.mirror);

  const isSelected = selectedMirror === mirror.value;
  if (isSelected) {
  }
  return (
    <div
      onClick={() => handleDoorSelection({ name: mirror.name, value: mirror.value, excelId: mirror.excelId, excelName: mirror.excelName })}
      className={`flex flex-col gap-2
      
        rounded-xl items-center cursor-pointer`}>
      <Avatar
        src={`/newMenuAssets/${mirror.name}.png`}
        className={`!flex !items-center !justify-center !rounded-lg !w-20 !h-20 ${isSelected ? "border-[3px] border-primary" : ""}`}></Avatar>
      {isSelected && (
        <div className="absolute flex items-center justify-center w-8 h-8 mt-5 bg-black bg-opacity-20 rounded-xl backdrop-blur-md">
          <svg
            width="18"
            height="18"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1073_431)">
              <path
                d="M8.95579 4.82966V5.20299C8.95529 6.07806 8.67193 6.92953 8.14798 7.6304C7.62403 8.33127 6.88755 8.844 6.04839 9.09211C5.20924 9.34023 4.31236 9.31043 3.49151 9.00717C2.67067 8.70391 1.96985 8.14344 1.49357 7.40934C1.01729 6.67524 0.791063 5.80685 0.84864 4.93367C0.906217 4.0605 1.24451 3.22933 1.81306 2.56412C2.38161 1.89892 3.14996 1.43532 4.00351 1.24247C4.85707 1.04962 5.7501 1.13786 6.54941 1.49401"
                stroke="#E4D5BE"
                stroke-width="0.811594"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.95798 1.95642L4.90001 6.01845L3.68262 4.80106"
                stroke="#E4D5BE"
                stroke-width="0.811594"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1073_431">
                <rect
                  width="9.33333"
                  height="9.33333"
                  fill="white"
                  transform="translate(0.333008 0.333374)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
      <p className="text-lg tracking-wide grow text-primary">{mirror.name}</p>
      {/* {isSelected && <TiTick />} */}
    </div>
  );
};
